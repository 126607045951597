import {FC, ReactElement, useEffect, useState} from "react";
import PaperContainer from "../../../../lab/Components/PaperContainer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {Fab, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import AddressInput from "../../../../lab/Input/AddressInputLab";
import {useMap, useOrganization, useOrganizationRequest} from "@kashalot-web/react/dist";


const UPDATE_WISHLIST_REQ_ID = "update-wishlist-req-asdnlfaposdbf"

const emptyWishlistItem: WishlistItem = {
    id: "",
    address: "",
    comment: "",
    type: "another"
}


const EmployeeWishlist: FC<IEmployeeWishlist> = ({employee, reset}): ReactElement => {
    const map = useMap()
    const {pushEmployeeWishList, deleteEmployeeWishList, updateEmployeeWishList} = useOrganization()
    const [status, remove] = useOrganizationRequest(UPDATE_WISHLIST_REQ_ID)

    useEffect(() => {
        if (status === "success" || status === "error") {
            remove()
            reset()
            setNewWishlistItem(emptyWishlistItem)
        }
    }, [status])

    useEffect(() => {
        setWishlist(employee.wishlist || [])
    }, [employee])

    const [wishlist, setWishlist] = useState<Array<WishlistItem>>(employee.wishlist || [])

    const mapType = (type: WishlistItem["type"]) => {
        switch (type) {
            case "home":
                return "Домашний адрес"
            case "work":
                return "Рабочий адрес"
            case "another":
                return "Дополнительный адрес"
        }
    }

    const buildTypeList = (type?: WishlistItem["type"]) => {
        let result: Array<WishlistItem["type"]> = ["home", "work"]
        const defaultWishlist = employee.wishlist
        if (defaultWishlist) {
            result = result.filter(itType => !defaultWishlist.map(item => item.type).includes(itType) || itType === type)
        }
        result.push("another")
        return result
    }

    const onChange = (index: number, param: "type" | "address" | "comment", value: string) => {
        setWishlist(wishlist.map((wishlistItem, ind) => {
            if (ind === index) {
                return {
                    ...wishlistItem,
                    [param]: value
                }
            }
            return wishlistItem
        }))
    }

    const onDelete = (index: number) => {
        const defaultWishlist = employee.wishlist
        if (defaultWishlist && index < defaultWishlist.length) {
            deleteEmployeeWishList(defaultWishlist[index], employee, UPDATE_WISHLIST_REQ_ID)
        }
    }

    const validate = (index: number) => {
        const defaultWishlist = employee.wishlist || []
        if (defaultWishlist.length <= index || wishlist.length <= index) {
            return false
        }
        return defaultWishlist[index].type !== wishlist[index].type
            || (defaultWishlist[index].address !== wishlist[index].address && wishlist[index].address)
            || defaultWishlist[index].comment !== wishlist[index].comment
    }

    const onSave = (index: number) => {
        if (validate(index) && index < wishlist.length) {
            updateEmployeeWishList(wishlist[index], employee, UPDATE_WISHLIST_REQ_ID)
        }
    }


    const [newWishlistItem, setNewWishlistItem] = useState<WishlistItem>(emptyWishlistItem)

    const handleNewWishlistItemUpdate = (param: "type" | "address" | "comment", value: string) => {
        setNewWishlistItem({...newWishlistItem, [param]: value})
    }

    const validateNewWishlistItem = () => {
        return newWishlistItem.address
    }

    const onCreate = () => {
        if (validateNewWishlistItem()) {
            pushEmployeeWishList(newWishlistItem, employee, UPDATE_WISHLIST_REQ_ID)
        }
    }


    return (
        <PaperContainer
            title="Избранные адреса"
            Icon={LocationOnIcon}
            item container
            direction={"column"}
            wrap={"nowrap"}
            spacing={2}
            sx={{p: '16px 0'}}
        >
            {wishlist.map((wishlistItem, index) => {
                return (
                    <Grid
                        item container
                        spacing={2}
                        key={`wishlist-item-${index}`}
                    >
                        <Grid item><FormControl>
                            <InputLabel id="demo-simple-select-label">Тип адреса</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                label="Тип адреса"
                                value={wishlistItem.type}
                                onChange={event => onChange(index, "type", event.target.value)}
                                sx={{width: 250}}
                            >
                                {buildTypeList(wishlistItem.type).map((type, ind) => {
                                    return (
                                        <MenuItem value={type} key={wishlistItem.id}>{mapType(type)}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl></Grid>
                        <Grid item><AddressInput
                            id={`address-input-id-${index}`}
                            label={"Адрес"}
                            defaultValue={wishlistItem.address}
                            onChange={newAddress => onChange(index, "address", newAddress)}
                            {...map}
                        /></Grid>
                        <Grid item><TextField
                            sx={{width: 250}}
                            label={"Комментарий"}
                            value={wishlistItem.comment}
                            onChange={event => onChange(index, "comment", event.target.value)}
                        /></Grid>
                        <Grid item>
                            <Fab
                                color="primary" size="medium"
                                onClick={() => onSave(index)}
                                disabled={!validate(index)}
                            ><SaveIcon /></Fab></Grid>
                        <Grid item><Fab
                            color="primary" size="medium"
                            onClick={() => onDelete(index)}
                        ><DeleteIcon /></Fab></Grid>
                    </Grid>
                )
            })}
            <Grid
                item container
                spacing={2}
            >
                <Grid item><FormControl>
                    <InputLabel id="demo-simple-select-label">Тип адреса</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        label="Тип адреса"
                        value={newWishlistItem.type}
                        onChange={event => handleNewWishlistItemUpdate("type", event.target.value)}
                        sx={{width: 250}}
                    >
                        {buildTypeList(newWishlistItem.type).map((type, ind) => {
                            return (
                                <MenuItem value={type} key={`new-w-i-${ind}`}>{mapType(type)}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl></Grid>
                <Grid item><AddressInput
                    id={`address-input-new-wishlist-item`}
                    label={"Адрес"}
                    defaultValue={newWishlistItem.address}
                    onChange={newAddress => handleNewWishlistItemUpdate("address", newAddress)}
                    {...map}
                /></Grid>
                <Grid item><TextField
                    label={"Комментарий"}
                    sx={{width: 250}}
                    value={newWishlistItem.comment}
                    onChange={event => handleNewWishlistItemUpdate("comment", event.target.value)}
                /></Grid>
                <Grid item><Fab
                    color="primary" size="medium"
                    onClick={onCreate}
                    disabled={!validateNewWishlistItem()}
                ><AddIcon /></Fab></Grid>
            </Grid>
        </PaperContainer>
    )
}

export default EmployeeWishlist