import {Grid, TextField} from "@mui/material";
import {FC, ReactElement, useEffect, useState} from "react";
import TaxiParks from "@kashalot-web/core/dist/TaxiInfo/TaxiParks"
import PaperContainer from "../../lab/Components/PaperContainer";
import StyledButton from "../StyledButton";
import PhoneInput from "../PhoneInput";
import RequisitesForm, {validateRequisitesForm} from "../RequisitesForm";

const TaxovichkofIcon = (props: any) => {
    return (
        <Grid item {...props}>
            <img
                width={50}
                src={TaxiParks.TAXOVICHKOF.logo}
            />
        </Grid>
    )
}

interface ITaxovichkofFormComponent {
    defaultTaxovichkofForm: TaxovichkofForm
    saveForm: (newForm: TaxovichkofForm) => void
    deleteForm: () => void
}

const TaxovichkofFormComponent: FC<ITaxovichkofFormComponent> = (props): ReactElement => {
    const {defaultTaxovichkofForm, saveForm, deleteForm} = props
    const [taxovichkofForm, setTaxovichkofForm] = useState<TaxovichkofForm>(defaultTaxovichkofForm)
    const taxovichkofHandleChanged = (param: "phone" | "phoneCode" | "cashCardID" | "cashlessCardID" | "secretWord" | "name", value: string) => setTaxovichkofForm({
        ...taxovichkofForm,
        [param]: value
    })

    useEffect(() => {
        setTaxovichkofForm(defaultTaxovichkofForm)
    }, [defaultTaxovichkofForm])

    const validate = () => {
        if (defaultTaxovichkofForm?.legalEntity && taxovichkofForm.legalEntity) {
            return taxovichkofForm.name !== defaultTaxovichkofForm.name
                || taxovichkofForm.phone !== defaultTaxovichkofForm.phone
                || taxovichkofForm.phoneCode !== defaultTaxovichkofForm.phoneCode
                || taxovichkofForm.cashCardID !== defaultTaxovichkofForm.cashCardID
                || taxovichkofForm.cashlessCardID !== defaultTaxovichkofForm.cashlessCardID
                || taxovichkofForm.secretWord !== defaultTaxovichkofForm.secretWord ||
                validateRequisitesForm(defaultTaxovichkofForm.legalEntity, taxovichkofForm.legalEntity)
        }
        return taxovichkofForm.name !== defaultTaxovichkofForm.name
            || taxovichkofForm.phone !== defaultTaxovichkofForm.phone
            || taxovichkofForm.phoneCode !== defaultTaxovichkofForm.phoneCode
            || taxovichkofForm.cashCardID !== defaultTaxovichkofForm.cashCardID
            || taxovichkofForm.cashlessCardID !== defaultTaxovichkofForm.cashlessCardID
            || taxovichkofForm.secretWord !== defaultTaxovichkofForm.secretWord ||
            !!taxovichkofForm.legalEntity
    }

    return (
        <PaperContainer
            title={"Таксовичкоф " + `(${defaultTaxovichkofForm.name})`}
            Icon={TaxovichkofIcon}
            direction={"column"}
            wrap={"nowrap"}
            container
            spacing={2}
            sx={{p: '16px 0'}}
        >
            <Grid item><TextField
                label={"Название"}
                value={taxovichkofForm.name}
                onChange={e => taxovichkofHandleChanged("name", e.target.value)}
            /></Grid>
            <Grid item container spacing={2}>
                <Grid item><PhoneInput
                    label={"Номер телефона"}
                    value={taxovichkofForm.phone}
                    onChange={e => taxovichkofHandleChanged("phone", e.target.value.match(/\d/g)?.join("") || "")}
                /></Grid>
                <Grid item><TextField
                    label={"Пароль"}
                    value={taxovichkofForm.phoneCode}
                    onChange={e => taxovichkofHandleChanged("phoneCode", e.target.value)}
                /></Grid>
            </Grid>
            <Grid item><TextField
                label={"ID налички"}
                value={taxovichkofForm.cashCardID}
                onChange={e => taxovichkofHandleChanged("cashCardID", e.target.value)}
            /></Grid>
            <Grid item container spacing={2}>
                <Grid item><TextField
                    label={"ID безнала"}
                    value={taxovichkofForm.cashlessCardID}
                    onChange={e => taxovichkofHandleChanged("cashlessCardID", e.target.value)}
                /></Grid>
                <Grid item><TextField
                    label={"Секретное слово"}
                    value={taxovichkofForm.secretWord}
                    onChange={e => taxovichkofHandleChanged("secretWord", e.target.value)}
                /></Grid>
            </Grid>

            <Grid item>
                <RequisitesForm
                    defaultRequisites={taxovichkofForm.legalEntity}
                    onChangeRequisites={newRequisites => {
                        setTaxovichkofForm({...taxovichkofForm, legalEntity: {...newRequisites, id: taxovichkofForm.legalEntity?.id || ""}})
                    }}
                />
            </Grid>

            <Grid item container spacing={2}>
                <Grid item><StyledButton
                    disabled={!validate()}
                    onClick={() => saveForm(taxovichkofForm)}
                >
                    Сохранить
                </StyledButton></Grid>
                <Grid item><StyledButton
                    onClick={deleteForm}
                >
                    Удалить
                </StyledButton></Grid>
            </Grid>
        </PaperContainer>
    )
}

export default TaxovichkofFormComponent