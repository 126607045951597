import {FC, ReactElement, useEffect, useState} from "react";
import {Autocomplete, Button, CircularProgress, Grid, Paper, TextField, Typography} from "@mui/material";
import MaterialTable, {MTableToolbar} from "@material-table/core";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import moment from "moment";
import Statuses from "@kashalot-web/core/dist/TaxiInfo/Statuses"
import StyledButton from "./StyledButton";


const makeTableData = (orders: Array<CreatedOrder>) => {
    return orders.map(order => ({
        title: order.title,
        date: moment(order.deadline, "HH:mm DD.MM.YYYY").format('DD.MM.YYYY'),
        price: order.price,
        paymentType: order.paymentType.title,
        vendor: order.vendor.name,
        from: order.route[0],
        to: order.route[order.route.length - 1],
    }))
}


type StatusesScope = { type: "all" | "completed" | "fraud", label: string }
const allStatusesScope: StatusesScope = {type: "all", label: "Все"}
const completedStatusesScope: StatusesScope = {type: "completed", label: "Завершенные"}
const fraudStatusesScope: StatusesScope = {type: "fraud", label: "Фрод"}
const statusesScopes = [allStatusesScope, completedStatusesScope, fraudStatusesScope]


interface IOrdersTable {
    loading: boolean
    orders: Array<CreatedOrder>
    getReport: () => void
    filters: Filters
    applyFilters: (newFilters: Filters) => void
    clients?: Array<ShortCompany | ShortBranch>
}

const OrdersTable: FC<IOrdersTable> = (props): ReactElement => {
    const {
        orders, loading,
        getReport,
        filters, applyFilters,
        clients
    } = props

    const [targetStatusesScope, setTargetStatusesScope] = useState<StatusesScope>(completedStatusesScope)

    const handleStatuses = (value: StatusesScope | null) => {
        if (value) {
            if (value.type === "fraud") {
                applyFilters({...filters, statuses: [Statuses.Status18]})
            } else if (value.type === "all") {
                applyFilters({...filters, statuses: []})
            } else if (value.type === "completed") {
                applyFilters({...filters, statuses: Statuses.completedStatuses()})
            }
        }
    }

    useEffect(() => {
        handleStatuses(targetStatusesScope)
    }, [targetStatusesScope])

    return (
        <MaterialTable
            isLoading={loading}
            style={{width: "100%"}}
            title={<Typography variant={"h4"}>Финансы</Typography>}
            columns={[
                {title: "Заказ", field: "title"},
                {title: "Дата подачи", field: "date"},
                {title: "Стоимость", field: "price"},
                {title: "Тип оплаты", field: "paymentType"},
                {title: "Перевозчик", field: "vendor"},
                {title: "Откуда", field: "from"},
                {title: "Куда", field: "to"},
            ]}
            data={makeTableData(orders)}
            options={{
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 50],
                filtering: true
            }}
            components={{
                Toolbar: props => {
                    return (
                        <Grid container direction="column" wrap="nowrap">
                            <MTableToolbar {...props} />
                            <Grid item container sx={{p: '9px'}}>
                                <Paper
                                    component={Grid}
                                    item container
                                    sx={{p: '18px 14px'}}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        spacing={2}
                                    >
                                        <Grid item container spacing={2}>
                                            <Grid item>
                                                <Autocomplete
                                                    sx={{width: 260}}
                                                    isOptionEqualToValue={(option, value) => option.type === value.type}
                                                    getOptionLabel={(option) => option.label}
                                                    options={statusesScopes}
                                                    value={targetStatusesScope}
                                                    onChange={(_, value) => value && setTargetStatusesScope(value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Статус"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {clients && (
                                                <Grid item>
                                                    <Autocomplete
                                                        sx={{width: 300}}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        getOptionLabel={(option) => option.name}
                                                        options={clients}
                                                        value={(() => {
                                                            return clients.find(client => {
                                                                if (filters.branchID && client._type === "short-branch") {
                                                                    return filters.branchID === client.id
                                                                }
                                                                if (!filters.branchID && filters.companyID && client._type === "short-company") {
                                                                    return filters.companyID === client.id
                                                                }
                                                                return null
                                                            })
                                                        })()}
                                                        onChange={(_, value) => {
                                                            if (!value) {
                                                                applyFilters({
                                                                    ...filters,
                                                                    companyID: undefined,
                                                                    branchID: undefined,
                                                                    ownerIDs: undefined
                                                                })
                                                            } else if (value._type == "short-company") {
                                                                applyFilters({
                                                                    ...filters,
                                                                    companyID: value.id,
                                                                    branchID: undefined,
                                                                    ownerIDs: undefined
                                                                })
                                                            } else {
                                                                applyFilters({
                                                                    ...filters,
                                                                    companyID: value.companyID,
                                                                    branchID: value.id,
                                                                    ownerIDs: undefined
                                                                })
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Клиенты"
                                                                InputProps={{
                                                                    ...params.InputProps
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid item container spacing={2}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                <Grid item>
                                                    <DatePicker
                                                        renderInput={(params) => <TextField sx={{width: 260}} {...params}
                                                                                            label="От"/>}
                                                        value={filters.dateStart.toDate()}
                                                        onChange={(newDate: any) => applyFilters({
                                                            ...filters, dateStart: moment(newDate.toISOString())
                                                        })}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <DatePicker
                                                        renderInput={(params) => <TextField sx={{width: 260}} {...params}
                                                                                            label="До"/>}
                                                        value={filters.dateEnd.toDate()}
                                                        onChange={(newDate: any) => applyFilters({
                                                            ...filters, dateEnd: moment(newDate.toISOString())
                                                        })}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item>
                                            <StyledButton onClick={getReport}>
                                                Скачать отчет
                                            </StyledButton>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
            }}
        />
    )
}

export default OrdersTable