import {FC, ReactElement, useState} from "react";
import OfficePageSketch from "../../../lab/OfficePageSketch";
import {useNavigate} from "react-router-dom";
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import CreateEmployee from "./CreateEmployee";
import CreateCompany from "./CreateCompany";
import CreateBranch from "./CreateBranch";

const CreateClientPage: FC = (): ReactElement => {
    const navigate = useNavigate()

    const [clientType, setClientType] = useState<"employee" | "company" | "branch">("employee")
    const handleClientTypeChange = (newType: string) => {
        if (newType === "employee" || newType === "company" || newType === "branch") {
            setClientType(newType)
        }
    }

    return (
        <OfficePageSketch
            title={"Создание клиента"}
            goBack={() => navigate(-1)}
            paperContainer={true}
        >
            <Grid item container direction={"column"} spacing={2} sx={{p: '0 14px'}}>
                <Grid
                    item container
                    direction={"column"}
                    spacing={2}
                >
                    <Grid item><Typography variant="h5">Тип клиента</Typography></Grid>
                    <Grid item><FormControl>
                        <InputLabel>Тип клиента</InputLabel>
                        <Select
                            value={clientType}
                            label="Тип клиента"
                            onChange={event => handleClientTypeChange(event.target.value)}
                        >
                            <MenuItem value="employee">Сотрудник</MenuItem>
                            <MenuItem value="company">Компания</MenuItem>
                            <MenuItem value="branch">Филиал</MenuItem>
                        </Select>
                    </FormControl></Grid>
                </Grid>
                {clientType === "employee" && <CreateEmployee/>}
                {clientType === "company" && <CreateCompany/>}
                {clientType === "branch" && <CreateBranch/>}
            </Grid>
        </OfficePageSketch>
    )
}

export default CreateClientPage