import {FC, ReactElement, useEffect} from "react";
import MaterialTable, {MTableToolbar} from "@material-table/core";
import {Button, Grid, Paper, Typography} from "@mui/material";
import {useDispatcher} from "@kashalot-web/react";
import {useDispatcherRequest} from "@kashalot-web/react/dist";
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import {useNavigate} from "react-router-dom";
import StyledButton from "../../../LocalUICore/StyledButton";
import {GET_CLIENTS_ID} from "../DispatcherRequestIds";

const mapClientsForTable = (clientsManager: IClientsManager | null) => {
    if (!clientsManager) {
        return []
    }
    type ResultType = {
        id: string,
        type: "worker" | "manager" | "director" | "company" | "branch",
        name: string,
        phone: string,
        mainCompany: string,
        active: boolean
    }
    const result: ResultType[] = []
    const getEmployeeType = (type: EmployeeType) => {
        if (type.id === EmployeeTypes.CompanyManager.id || type.id === EmployeeTypes.BranchManager.id) {
            return "manager"
        }
        if (type.id === EmployeeTypes.CompanyDirector.id || type.id === EmployeeTypes.BranchDirector.id) {
            return "director"
        }
        if (type.id === EmployeeTypes.CompanyWorker.id || type.id === EmployeeTypes.BranchWorker.id) {
            return "worker"
        }
        return "worker"
    }
    clientsManager.getCompanies().forEach(company => company.users.forEach(user => {
        if (user.type.id === EmployeeTypes.CompanyWorker.id) {
            result.push({
                id: user.id,
                type: getEmployeeType(user.type),
                name: user.fio,
                phone: user.phone,
                mainCompany: company.name,
                active: user.active
            })
        } else {
            result.push({
                id: user.id,
                type: getEmployeeType(user.type),
                name: user.fio + `(${user.email})`,
                phone: user.phone,
                mainCompany: company.name,
                active: user.active
            })
        }
    }))
    clientsManager.getBranches().forEach(branch => branch.users.forEach(user => {
        if (user.type.id === EmployeeTypes.BranchWorker.id) {
            result.push({
                id: user.id,
                type: getEmployeeType(user.type),
                name: user.fio,
                phone: user.phone,
                mainCompany: branch.companyName,
                active: user.active
            })
        } else {
            result.push({
                id: user.id,
                type: getEmployeeType(user.type),
                name: user.fio + `(${user.email})`,
                phone: user.phone,
                mainCompany: branch.companyName,
                active: user.active
            })
        }
    }))
    clientsManager.getCompanies().forEach(company => result.push({
        id: company.id,
        type: "company",
        name: company.name,
        phone: company.phoneNumber,
        mainCompany: "",
        active: true
    }))
    clientsManager.getBranches().forEach(branch => result.push({
        id: branch.id,
        type: "branch",
        name: branch.name,
        phone: branch.phoneNumber,
        mainCompany: branch.companyName,
        active: true
    }))
    return result
}

const ClientsSection: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {getClients, clientsManager} = useDispatcher()
    const [status, removeRequest] = useDispatcherRequest(GET_CLIENTS_ID)

    useEffect(() => {
        getClients(GET_CLIENTS_ID)
        return () => removeRequest()
    }, [])

    return (
        <MaterialTable
            style={{width: "100%"}}
            title={<Typography variant={"h4"}>Клиенты</Typography>}
            data={mapClientsForTable(clientsManager)}
            isLoading={status === "pending"}
            columns={[
                {
                    title: "Тип",
                    field: "type",
                    lookup: {
                        worker: "Сотрудник",
                        manager: "Менеджер",
                        director: "Управляющий",
                        company: "Компания",
                        branch: "Филиал"
                    },
                    defaultFilter: ["company", "branch"]
                },
                {title: "Активен", field: "active", lookup: {"true": 'Активен', "false": 'Отключен'}, defaultFilter: ["true"]},
                {title: "Название", field: "name"},
                {title: "Номер телефона", field: "phone"},
                {title: "Главная компания", field: "mainCompany"},
            ]}
            options={{
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 50],
                filtering: true,
                rowStyle: rowData => {
                    if (rowData.active === false) {
                        return {backgroundColor: "#EEE"}
                    }
                    return {}
                },
            }}
            onRowClick={(event, data) => {
                if (data && data.id) {
                    navigate(`/client/${data.id}`)
                }
            }}
            components={{
                Toolbar: props => {
                    return (
                        <Grid container direction="column" wrap="nowrap">
                            <MTableToolbar {...props} />
                            <Grid item container sx={{p: '9px'}}>
                                <Paper
                                    component={Grid}
                                    item container
                                    sx={{p: '9px'}}
                                >
                                    <StyledButton onClick={() => navigate('/create_client')}>
                                        Создать клиента
                                    </StyledButton>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
            }}
        />
    )
}

export default ClientsSection