import {FC, ReactElement, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatcher, useDispatcherRequest, useOrganization} from "@kashalot-web/react/dist";
import {Backdrop, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import CreateEmployee from "../CreateClientPage/CreateEmployee";
import CreateCompany from "../CreateClientPage/CreateCompany";
import CreateBranch from "../CreateClientPage/CreateBranch";
import OfficePageSketch from "../../../lab/OfficePageSketch";
import {LoadingScreen} from "@kashalot-web/ui/dist";
import OrganizationsGroup from "./OrganizationsGroup";
import VendorsGroup from "./VendorsGroup";
import ProfileGroup from "./ProfileGroup";

const GET_DISPATCHER_OFFICE_REQ_ID = "get-disp-office-2q34nsnd"

const GroupPage: FC = (): ReactElement => {
    const {id} = useParams<{id: string}>()
    const {getDispatcherOfficeDetails, dispatcherOffice} = useDispatcher()
    const navigate = useNavigate()
    const [getDispatcherOfficeStatus] = useDispatcherRequest(GET_DISPATCHER_OFFICE_REQ_ID)
    const [group, setGroup] = useState<DispatcherGroup | null>(null)

    useEffect(() => {
        getDispatcherOfficeDetails(GET_DISPATCHER_OFFICE_REQ_ID)
    }, [])

    useEffect(() => {
        const targetGroup = dispatcherOffice?.groups.find(iterGroup => iterGroup.id === id) || null
        setGroup(targetGroup)
    }, [dispatcherOffice])

    const reset = () => {
        console.log("reset")
        getDispatcherOfficeDetails(GET_DISPATCHER_OFFICE_REQ_ID)
    }

    if (!group || !dispatcherOffice) {
        return <LoadingScreen />
    }

    return (
        <OfficePageSketch
            title={group.name}
            goBack={() => navigate(-1)}
            paperContainer={false}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={getDispatcherOfficeStatus === "pending"}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item container direction={"column"} wrap={"nowrap"}>
                <ProfileGroup group={group} reset={reset} />
                <VendorsGroup group={group} reset={reset} vendorForms={dispatcherOffice.vendorForms}/>
                <OrganizationsGroup group={group} reset={reset} allGroups={dispatcherOffice.groups}/>
            </Grid>
        </OfficePageSketch>
    )
}

export default GroupPage