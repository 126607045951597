import {FC, ReactElement, useState} from "react";
import {Autocomplete, Button, Grid, Paper, TextField, Typography} from "@mui/material";
import MaterialTable, {MTableToolbar} from "@material-table/core";
import TaxiParks from "@kashalot-web/core/dist/TaxiInfo/TaxiParks"
import TaxiClasses from "@kashalot-web/core/dist/TaxiInfo/TaxiClasses"
import {useDispatcher, useDispatcherRequest} from "@kashalot-web/react/dist";
import VendorModalContainer from "./VendorModalContainer";

const UPDATE_GROUP_RULES_ID = "update-group-rules-mkrnon4fo"

const mapVendorsForTable = (rules: Array<Rule>, vendorForms: VendorForms) => {
    return rules.map(rule => {
        const vendorForm = vendorForms.find(it => it.id === rule.vendorFormID)
        return {
            vendorFormID: rule.vendorFormID,
            name: vendorForm?.name || "Удалено",
            vendorName: vendorForm?.vendor.name || "",
        }
    })
}

const buildVendorFormList = (rules: Array<Rule>, vendorForms: VendorForms) => {
    return vendorForms.filter(vendorForm => !rules.find(rule => rule.vendorFormID === vendorForm.id))
}

const buildVendorRule = (vendorForm: VendorForm): Rule => {
    const emptyVendorRule: Rule = {
        cash: {
            "0": {value: 0, type: ("plusPercent" as "plusPercent")},
            "15": {value: 0, type: ("plusPercent" as "plusPercent")},
        },
        cashless: {
            "0": {value: 0, type: ("plusPercent" as "plusPercent")},
            "15": {value: 0, type: ("plusPercent" as "plusPercent")},
        },
        vendorFormID: vendorForm.id
    }
    return emptyVendorRule
}

interface IVendorsGroup {
    group: DispatcherGroup
    reset: () => void
    vendorForms: VendorForms
}

const VendorsGroup: FC<IVendorsGroup> = ({group, reset, vendorForms}): ReactElement => {
    const {addRuleToGroup, deleteRuleFromGroup, updateRuleInGroup} = useDispatcher()
    const [updatingStatus] = useDispatcherRequest(UPDATE_GROUP_RULES_ID)
    const [selectedVendorForm, setSelectedVendorForm] = useState<VendorForm | null>(null)
    const validate = () => !!selectedVendorForm && updatingStatus !== "pending"
    const addVendorForm = () => {
        if (!selectedVendorForm) {
            return
        }
        const rule = buildVendorRule(selectedVendorForm)
        if (!rule || !validate()) {
            return
        }
        addRuleToGroup(group.id, rule, UPDATE_GROUP_RULES_ID)
        setSelectedVendorForm(null)
        reset()
    }
    const removeVendorForm = (vendorFormID: string) => {
        deleteRuleFromGroup(group.id, vendorFormID, UPDATE_GROUP_RULES_ID)
        setSelectedVendorForm(null)
        reset()
    }

    const [indexRule, setIndexRule] = useState<number>(0)
    const [isOpenVendor, setIsOpenVendor] = useState<boolean>(false)
    const openVendor = (index: number) => {
        setIsOpenVendor(true)
        setIndexRule(index)
    }
    const closeVendor = () => setIsOpenVendor(false)
    // const saveRules = (rules: Array<Rule>) => {
    //     // updateDispatcherGroupRules({...group, rules: rules}, UPDATE_GROUP_RULES_ID)
    //     setIsOpenVendor(false)
    //     reset()
    // }
    const saveRule = (rule: Rule) => {
        updateRuleInGroup(group.id, rule, UPDATE_GROUP_RULES_ID)
        setIsOpenVendor(false)
        reset()
    }

    return (
        <>
            {indexRule >= 0 && indexRule < group.rules.length && <VendorModalContainer
                open={isOpenVendor}
                onClose={closeVendor}
                children={<></>}
                defaultRule={group.rules[indexRule]}
                save={saveRule}
            />}
            <MaterialTable
                style={{width: "100%"}}
                title={<Typography variant={"h4"}>Перевозчики</Typography>}
                columns={[
                    {title: "Название", field: "name", editable: 'never'},
                    {title: "Перевозчик", field: "vendorName", editable: 'never'},
                ]}
                data={mapVendorsForTable(group.rules, vendorForms)}
                options={{
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 20],
                    filtering: false
                }}
                onRowClick={(event, data) => {
                    try {
                        // @ts-ignore
                        openVendor(data.tableData.id)
                    } catch (e) {
                    }
                }}
                editable={{
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            removeVendorForm(oldData.vendorFormID)
                            resolve(true)
                        })
                }}
                components={{
                    Toolbar: props => {
                        return (
                            <Grid container direction="column" wrap="nowrap">
                                <MTableToolbar {...props} />
                                <Grid item container sx={{p: '9px'}}>
                                    <Paper
                                        component={Grid}
                                        item container
                                        alignItems={"center"}
                                        sx={{p: '18px 14px', boxSizing: "border-box"}}
                                    >
                                        <Grid item sx={{mr: '9px'}}>
                                            <Autocomplete
                                                sx={{width: 400}}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option.vendor.name + " - " + option.name}
                                                options={buildVendorFormList(group.rules, vendorForms)}
                                                value={selectedVendorForm}
                                                onChange={(_, value) => setSelectedVendorForm(value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Таксопарки"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item sx={{mr: '9px'}}>
                                            <Button
                                                variant={"contained"}
                                                disabled={!validate()}
                                                onClick={addVendorForm}
                                            >
                                                Добавить
                                            </Button>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )
                    }
                }}
            />
        </>
    )
}

export default VendorsGroup