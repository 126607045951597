import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import {useDispatcher, useDispatcherRequest, useOrganization, useOrganizationRequest} from "@kashalot-web/react/dist";
import PaperContainer from "../../../lab/Components/PaperContainer";
import PersonIcon from '@mui/icons-material/Person';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import InputMask from "react-input-mask";
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EmployeeWishlist from "../../CompanyPages/EmployeePage/EmployeeWishlist";


interface IEmployeeQuestionnaire {
    employee: Employee
    reset: () => void
}

const REACTIVATE_ID = "reactivate-" + makeID(8)
const UPDATE_EMPLOYEE_ID = "update-employee-" + makeID(8)

const EmployeeQuestionnaire: FC<IEmployeeQuestionnaire> = ({employee, reset}): ReactElement => {
    const {reactivateEmployee, updateEmployee} = useOrganization()


    const [employeeDetails, setEmployeeDetails] = useState<Employee>(employee)
    useEffect(() => {
        setEmployeeDetails(employee)
    }, [employee])
    const handleEmployeeDetails = (param: "fio" | "email" | "phone", value: string) =>
        setEmployeeDetails({...employeeDetails, [param]: value})
    const handleEmployeeTypeChange = (newTypeID: string | number) => {
        const targetType = [...EmployeeTypes.getCompanyEmployees(), ...EmployeeTypes.getBranchEmployees()].find(type => type.id.toString() === newTypeID.toString())
        if (targetType) {
            setEmployeeDetails({...employeeDetails, type: targetType})
        }
    }
    const [statusSave, removeSaveReq] = useOrganizationRequest(UPDATE_EMPLOYEE_ID)
    const validate = () => {
        const {fio, email, phone, type} = employeeDetails
        return fio && PhoneNumber.validateWithMask(phone) &&
            (type.id === EmployeeTypes.BranchWorker.id || type.id === EmployeeTypes.CompanyWorker.id || email) &&
            (fio !== employee.fio || phone !== employee.phone || type.id !== employee.type.id || email !== employee.email) &&
            statusSave !== "pending"
    }
    const onSave = () => {
        updateEmployee(employeeDetails, UPDATE_EMPLOYEE_ID)
    }
    useEffect(() => {
        if (statusSave === "success" || statusSave === "error") {
            reset()
            removeSaveReq()
        }
    }, [statusSave])
    const [statusReactivate, removeReactiverReq] = useOrganizationRequest(REACTIVATE_ID)
    const onReactivate = () => {
        const action = employee.active ? "0" : "1"
        reactivateEmployee(employee, action, REACTIVATE_ID)
    }
    useEffect(() => {
        if (statusReactivate === "success" || statusReactivate === "error") {
            reset()
            removeReactiverReq()
        }
    }, [statusReactivate])


    return (
        <>
            <PaperContainer
                title={"Профиль"}
                Icon={PersonIcon}
                direction={"column"}
                container
                sx={{p: "20px 0"}}
                spacing={2}
            >
                <Grid item><FormControl>
                    <InputLabel>Тип сотрудника</InputLabel>
                    <Select
                        label="Тип сотрудника"
                        value={employeeDetails.type.id}
                        onChange={event => handleEmployeeTypeChange(event.target.value)}
                    >
                        {EmployeeTypes.getCompanyEmployees().map(t => t.id).includes(employeeDetails.type.id) && (
                            EmployeeTypes.getCompanyEmployees().map((type, index) => (
                                <MenuItem key={`com-em-${index}`} value={type.id}>{type.label}</MenuItem>
                            ))
                        )}
                        {EmployeeTypes.getBranchEmployees().map(t => t.id).includes(employeeDetails.type.id) && (
                            EmployeeTypes.getBranchEmployees().map((type, index) => (
                                <MenuItem key={`br-em-${index}`} value={type.id}>{type.label}</MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl></Grid>
                <Grid item><TextField
                    sx={{width: 300}}
                    label={"ФИО"}
                    value={employeeDetails.fio}
                    onChange={event => handleEmployeeDetails("fio", event.target.value)}
                /></Grid>
                {
                    (employeeDetails.type.id === EmployeeTypes.BranchDirector.id ||
                        employeeDetails.type.id === EmployeeTypes.BranchManager.id ||
                        employeeDetails.type.id === EmployeeTypes.CompanyDirector.id ||
                        employeeDetails.type.id === EmployeeTypes.CompanyManager.id) && (
                        <Grid item><TextField
                            sx={{width: 300}}
                            label={"Email"}
                            value={employeeDetails.email}
                            onChange={event => handleEmployeeDetails("email", event.target.value)}
                        /></Grid>
                    )
                }
                <Grid item><InputMask
                    mask="+7 (999) 999-9999"
                    value={employeeDetails.phone ? employeeDetails.phone : "+"}
                    onChange={(event) => handleEmployeeDetails("phone", event.target.value)}
                    children={((inputProps: any) =>
                        <TextField
                            {...inputProps}
                            sx={{width: 300}}
                            label={"Номер телефона"}
                            type="tel"
                        />) as unknown as ReactNode
                    }
                /></Grid>
                <Grid item container spacing={2}>
                    <Grid item><Button
                        variant={"contained"}
                        disabled={!validate()}
                        onClick={onSave}
                    >Сохарнить</Button></Grid>
                    <Grid item><Button
                        variant={"contained"}
                        onClick={onReactivate}
                    >{employee.active ? "Отключить" : "Включить"}</Button></Grid>
                </Grid>
            </PaperContainer>

            <EmployeeWishlist employee={employee} reset={reset} />
        </>
    )
}

export default EmployeeQuestionnaire