import {FC, ReactElement, useEffect, useState} from "react";
import PaperContainer from "./PaperContainer";
import BadgeIcon from '@mui/icons-material/Badge';
import {Autocomplete, Button, CircularProgress, Grid, TextField} from "@mui/material";

interface ICompanyBelongDispatcher {
    defaultCompany: Company | Branch
    save?: (dispatcher_office_id: string) => void
    saving?: boolean
    dispatcherOffices: DispatcherOffice[] | null
}

const CompanyBelongDispatcher: FC<ICompanyBelongDispatcher> = ({defaultCompany, save, dispatcherOffices}): ReactElement => {
    const [targetDispatcherOffice, setTargetDispatcherOffice] = useState<DispatcherOffice | null>(null)
    const validate = () => {
        if (dispatcherOffices) {
            const dispatcherOffice = dispatcherOffices.find(d => d.id === defaultCompany.dispatcherOfficeID)
            return targetDispatcherOffice?.id !== dispatcherOffice?.id
        }
        return false
    }

    useEffect(() => {
        if (dispatcherOffices) {
            const dispatcherOffice = dispatcherOffices.find(d => d.id === defaultCompany.dispatcherOfficeID) || null
            setTargetDispatcherOffice(dispatcherOffice)
        } else {
            setTargetDispatcherOffice(null)
        }
    }, [dispatcherOffices])

    const [loadingDispatcherOffices, setLoadingDispatcherOffices] = useState<boolean>(!dispatcherOffices)
    useEffect(() => {
        setLoadingDispatcherOffices(!dispatcherOffices)
    }, [dispatcherOffices])


    return (
        <PaperContainer
            title={"Диспетчерская"}
            Icon={BadgeIcon}
            direction={"column"}
            container
            spacing={2}
            sx={{p: '16px 0'}}
        >
            <Grid item>
                <Autocomplete
                    sx={{ width: 300 }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    options={dispatcherOffices || []}
                    loading={loadingDispatcherOffices}
                    value={targetDispatcherOffice}
                    onChange={(_, newValue) => setTargetDispatcherOffice(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Диспетчерская"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loadingDispatcherOffices ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            {!!save && (
                <Grid item><Button
                    variant={"contained"}
                    disabled={!validate()}
                    onClick={() => save(targetDispatcherOffice?.id || "")}
                >Сохранить</Button></Grid>
            )}
        </PaperContainer>
    )
}

export default CompanyBelongDispatcher