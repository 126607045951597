import {FC, ReactElement, useEffect, useState} from "react";
import {Button, Grid, Paper, TextField} from "@mui/material";
import PaperContainer from "../../../lab/Components/PaperContainer";
import PersonIcon from '@mui/icons-material/Person';
import {useDispatcher, useDispatcherRequest} from "@kashalot-web/react/dist";

interface IProfileGroup {
    group: DispatcherGroup
    reset: () => void
}

const UPDATE_GROUP_DETAILS_REQ_ID = "update-group-details-req-3nrkqfnjldk"

const ProfileGroup: FC<IProfileGroup> = ({group, reset}): ReactElement => {
    const {updateGroupDetails} = useDispatcher()
    const [status] = useDispatcherRequest(UPDATE_GROUP_DETAILS_REQ_ID)
    useEffect(() => {
        if (status === "success" || status === "error") {
            reset()
        }
    }, [status])

    const [groupName, setGroupName] = useState<string>(group.name)

    return (
        <PaperContainer
            Icon={PersonIcon}
            title={"Профиль группы"}
            container item
            direction={"column"}
            wrap={"nowrap"}
            spacing={2}
            sx={{p: "16px 0"}}
        >
            <Grid item>
                <TextField
                    value={groupName}
                    label={"Название"}
                    onChange={event => setGroupName(event.target.value)}
                />
            </Grid>
            <Grid item>
                <Button
                    variant={"contained"}
                    disabled={!(!!groupName && groupName !== group.name) || status === "pending"}
                    onClick={() => updateGroupDetails({...group, name: groupName}, UPDATE_GROUP_DETAILS_REQ_ID)}
                >
                    Сохранить
                </Button>
            </Grid>
        </PaperContainer>
    )
}

export default ProfileGroup