import {FC, ReactElement} from "react"
import {Routes, Route, Navigate} from 'react-router-dom'

import LoginPage from "../pages/LoginPage"
import {useProfile} from "@kashalot-web/react/dist"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import CompanyMainPage from "../pages/CompanyPages/MainCompanyPage";
import CreateEmployeePage from "../pages/CompanyPages/CreateEmployeePage";
import EmployeePage from "../pages/CompanyPages/EmployeePage";
import AdminMainPage from "../pages/AdminPages/MainAdminPage";
import MainDispatcherPage from "../pages/DispatcherPages/MainDispatcherPage";
import CreateClientPage from "../pages/DispatcherPages/CreateClientPage";
import DispatcherClientPage from "../pages/DispatcherPages/ClientPage";
import AdminClientPage from "../pages/AdminPages/ClientPage";
import CreateDispatcherOfficePage from "../pages/AdminPages/CreateDisaptcherOfficePage";
import GroupPage from "../pages/DispatcherPages/GroupPage";
import CreateGroupPage from "../pages/DispatcherPages/CreateGroupPage";

const RoutesManager: FC = (): ReactElement => {
    const {userInfo} = useProfile()

    if (!userInfo) {
        return (
            <Routes>
                <Route path="/login" element={ <LoginPage/>} />
                <Route
                    path="*"
                    element={<Navigate to="/login" replace />}
                />
            </Routes>
        )
    }

    if (
        [
            EmployeeTypes.CompanyManager, EmployeeTypes.CompanyDirector,
            EmployeeTypes.BranchManager, EmployeeTypes.BranchDirector,
        ].map(employee => employee.id).includes(userInfo.employeeType.id)
    ) {
        return (
            <Routes>
                <Route path="/main" element={<CompanyMainPage section="main"/>} />
                <Route path="/reports" element={<CompanyMainPage section="reports"/>} />
                <Route path="/employees" element={<CompanyMainPage section="employees"/>} />
                <Route path="/create_employee" element={ <CreateEmployeePage/>} />
                <Route path="/employee/:id" element={<EmployeePage/>} />
                <Route
                    path="*"
                    element={<Navigate to="/reports" replace />}
                />
            </Routes>
        )
    }

    if ([EmployeeTypes.Dispatcher, EmployeeTypes.DispatcherWorker]
            .map(employee => employee.id).includes(userInfo.employeeType.id)
    ) {
        return (
            <Routes>
                <Route path="/legal_entities" element={<MainDispatcherPage section="legal_entities"/>} />
                <Route path="/vendors" element={<MainDispatcherPage section="vendors"/>} />
                <Route path="/groups" element={<MainDispatcherPage section="groups"/>} />
                <Route path="/group/:id" element={<GroupPage />} />
                <Route path="/create_group" element={<CreateGroupPage />} />
                <Route path="/transactions" element={<MainDispatcherPage section="transactions"/>} />
                <Route path="/reports" element={<MainDispatcherPage section="reports"/>} />
                <Route path="/client/:id" element={<DispatcherClientPage />} />
                <Route path="/clients" element={<MainDispatcherPage section="clients"/>} />
                <Route path="/create_client" element={<CreateClientPage />} />
                <Route path="/receipts" element={<MainDispatcherPage section="receipts"/>} />
                <Route
                    path="*"
                    element={<Navigate to="/reports" replace />}
                />
            </Routes>
        )
    }

    if ([EmployeeTypes.Admin].map(employee => employee.id).includes(userInfo.employeeType.id)) {
        return (
            <Routes>
                <Route path="/dispatcher_offices" element={<AdminMainPage section="dispatcher_offices"/>} />
                <Route path="/create_dispatcher_office" element={<CreateDispatcherOfficePage />} />
                <Route path="/clients" element={<AdminMainPage section="clients"/>} />
                <Route path="/client/:id" element={<AdminClientPage/>} />
                <Route
                    path="*"
                    element={<Navigate to="/dispatcher_offices" replace />}
                />
            </Routes>
        )
    }

    return (<>Неизваестный тип</>)
}

export default RoutesManager