import {FC, ReactElement, ReactNode, useState} from "react";
import PersonIcon from "@mui/icons-material/Person";
import {Button, Grid, TextField} from "@mui/material";
import InputMask from "react-input-mask";
import AddressInput from "../Input/AddressInputLab";
import PaperContainer from "./PaperContainer";
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber";

interface IBranchProfile {
    branch: Branch
    suggestionsRequests:  SuggestionsRequest[]
    pushSuggestionRequest: (id: string, inputValue: string) => void
    onSave?: () => void
}

const BranchProfile: FC<IBranchProfile> = ({branch, onSave, suggestionsRequests, pushSuggestionRequest}): ReactElement => {
    const [branchProfile, setBranchProfile] = useState<Branch>(branch)
    const handleCompanyProfile = (param: "name" | "phoneNumber" | "address", value: string) =>
        setBranchProfile({...branchProfile, [param]: value})
    const validateProfileBranch = () => {
        const {name, address, phoneNumber} = branchProfile
        return name && address && PhoneNumber.validateWithMask(phoneNumber) &&
            (name !== branch.name || address !== branch.address || phoneNumber !== branch.phoneNumber)
    }


    return (
        <PaperContainer
            title={"Профиль"}
            Icon={PersonIcon}
            direction={"column"}
            container
            spacing={2}
            sx={{p: '16px 0'}}
        >
            <Grid item>
                <TextField
                    label={"Название"}
                    value={branchProfile.name}
                    onChange={event => handleCompanyProfile("name", event.target.value)}
                    InputProps={{readOnly: !onSave}}
                />
            </Grid>
            <Grid item><InputMask
                mask="+7 (999) 999-9999"
                value={branchProfile.phoneNumber ? branchProfile.phoneNumber : "+"}
                onChange={(event) => handleCompanyProfile("phoneNumber", event.target.value)}
                children={((inputProps: any) =>
                    <TextField
                        {...inputProps}
                        label={"Номер телефона"}
                        type="tel"
                        InputProps={{readOnly: !onSave}}
                    />) as unknown as ReactNode
                }
            /></Grid>
            {!!onSave ? (
                <Grid item><AddressInput
                    id={`company-edit-address-13kmf;;m2`}
                    label={"Адрес"}
                    defaultValue={branchProfile.address}
                    onChange={(newAddress) => handleCompanyProfile("address", newAddress)}
                    suggestionsRequests={suggestionsRequests}
                    pushSuggestionRequest={pushSuggestionRequest}
                /></Grid>
            ) : (
                <Grid item><TextField
                    sx={{width: 300}}
                    value={branchProfile.address}
                    InputProps={{readOnly: true}}
                /></Grid>
            )}
            {!!onSave && (
                <Grid item><Button
                    variant={"contained"}
                    disabled={!validateProfileBranch()}
                    onClick={onSave}
                >Сохранить</Button></Grid>
            )}
        </PaperContainer>
    )
}

export default BranchProfile