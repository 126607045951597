import {FC, ReactElement, useEffect} from "react";
import MaterialTable, {MTableToolbar} from "@material-table/core"
import {Button, Grid, Paper, Typography} from "@mui/material";
import {useOrganization} from "@kashalot-web/react";
import {useNavigate} from "react-router-dom";
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import {useOrganizationRequest, useProfile} from "@kashalot-web/react/dist";
import {GET_EMPLOYEES_ID} from "../CompanyRequestIDs";

const makeTableData = (employees: Employee[]) => {
    return employees.map(employee => {
        let name: string = ""
        if (employee.type.id === EmployeeTypes.CompanyWorker.id || employee.type.id === EmployeeTypes.BranchWorker.id) {
            name = employee.fio
        } else if (employee.fio) {
            name = `${employee.fio} (${employee.email})`
        } else {
            name = employee.email
        }
        return {
            name: name,
            phone: employee.phone,
            id: employee.id,
            type: employee.type.id,
            active: employee.active //? "Активен" : "Отключен"
        }
    })
}

const makeEmployeeTypes = (userInfo: UserInfo | null) => {
    if (userInfo) {
        if (EmployeeTypes.getBranchEmployees().map(employeeType => employeeType.id).includes(userInfo.employeeType.id)) {
            return {
                [EmployeeTypes.BranchWorker.id.toString()]: EmployeeTypes.BranchWorker.label,
                [EmployeeTypes.BranchManager.id.toString()]: EmployeeTypes.BranchManager.label,
                [EmployeeTypes.BranchDirector.id.toString()]: EmployeeTypes.BranchDirector.label,
            }
        } else if (EmployeeTypes.getCompanyEmployees().map(employeeType => employeeType.id).includes(userInfo.employeeType.id)) {
            return {
                [EmployeeTypes.CompanyWorker.id.toString()]: EmployeeTypes.CompanyWorker.label,
                [EmployeeTypes.CompanyManager.id.toString()]: EmployeeTypes.CompanyManager.label,
                [EmployeeTypes.CompanyDirector.id.toString()]: EmployeeTypes.CompanyDirector.label,
            }
        }
    }
}


const EmployeesSection: FC = (): ReactElement => {
    const navigate = useNavigate()

    const {userInfo} = useProfile()
    const {getEmployeesList, employeesManager} = useOrganization()
    const [status] = useOrganizationRequest(GET_EMPLOYEES_ID)

    useEffect(() => {
        getEmployeesList(GET_EMPLOYEES_ID)
    }, [])

    return (
        <MaterialTable
            isLoading={status === "pending"}
            style={{width: "100%"}}
            title={<Typography variant={"h4"}>Сотрудники</Typography>}
            columns={[
                {title: "Имя", field: "name"},
                {title: "Активен", field: "active", lookup: {"true": 'Активен', "false": 'Отключен'}, defaultFilter: ["true"]},
                {title: "Номер телефона", field: "phone"},
                {title: "Тип сотрудника", field: "type", lookup: makeEmployeeTypes(userInfo)},
            ]}
            data={makeTableData(employeesManager?.getAllEmployees() || [])}
            options={{
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 50],
                filtering: true,
                rowStyle: rowData => {
                    if (!rowData.active) {
                        return {backgroundColor: "#EEE"}
                    }
                    return {}
                },
            }}
            components={{
                Toolbar: props => {
                    return (
                        <Grid container direction="column" wrap="nowrap">
                            <MTableToolbar {...props} />
                            <Grid item container sx={{p: '9px'}}>
                                <Paper
                                    component={Grid}
                                    item container
                                    sx={{p: '9px'}}
                                >
                                    <Button variant="contained" onClick={() => navigate('/create_employee')}>
                                        Создать сотрудника
                                    </Button>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
            }}
            onRowClick={(event, data) => {
                if (data && data.id) {
                    navigate(`/employee/${data.id}`)
                }
            }}
        />
    )
}

export default EmployeesSection