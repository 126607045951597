import {FC, ReactElement, useEffect} from "react";
import CompanyProfile from "../../../lab/Components/CompanyProfile";
import {
    useMap,
    useOrganization,
    useOrganizationRequest
} from "@kashalot-web/react/dist";


const SAVE_COMPANY_REQ = "save-company-req-asdmflkan"


interface ICompanyProfileContainer {
    company: Company
    reset: () => void
}

const CompanyProfileContainer: FC<ICompanyProfileContainer> = ({company, reset}): ReactElement => {
    const {suggestionsRequests, pushSuggestionRequest} = useMap()
    const {updateOrganizationDetails} = useOrganization()
    const [status, remove] = useOrganizationRequest(SAVE_COMPANY_REQ)

    useEffect(() => {
        if (status === "success") {
            remove()
            reset()
        }
    }, [status])

    return (
        <CompanyProfile
            company={company}
            suggestionsRequests={suggestionsRequests}
            pushSuggestionRequest={pushSuggestionRequest}
            onSave={newCompany => updateOrganizationDetails(newCompany, SAVE_COMPANY_REQ)}
        />
    )
}

export default CompanyProfileContainer