import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from "react-router-dom";
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import {useOrganization, useProfile, useOrganizationRequest} from "@kashalot-web/react/dist";
import InputMask from "react-input-mask";
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"
import {LoadingScreen} from "@kashalot-web/ui/dist";
import OfficePageSketch from "../../../lab/OfficePageSketch";
import {useSnackbar} from "notistack";
import {FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField} from "@mui/material";
import StyledButton from "../../../LocalUICore/StyledButton";
import PhoneInput from "../../../LocalUICore/PhoneInput"

const REQUEST_ID = "create-employee"

const CreateEmployeePage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()

    const {userInfo} = useProfile()
    const {createEmployee} = useOrganization()
    const [creatingStatus, creatingRemove] = useOrganizationRequest(REQUEST_ID)

    const [newEmployee, setNewEmployee] = useState<EmployeeForm>({
        fio: "",
        email: "",
        phone: "",
        type: EmployeeTypes.CompanyWorker,
        companyID: userInfo?.companyID,
        branchID: userInfo?.branchID,
    })

    useEffect(() => {
        return () => {
            creatingRemove()
        }
    }, [])

    useEffect(() => {
        if (userInfo && EmployeeTypes.getBranchEmployees().map(employeeType => employeeType.id).includes(userInfo.employeeType.id)) {
            setNewEmployee({...newEmployee, type: EmployeeTypes.BranchWorker})
        }
    }, [userInfo])

    const handleChangeType = (newTypeID: any) => {
        const newType = EmployeeTypes.getAllTypes().find(type => type.id === newTypeID)
        if (newType) {
            setNewEmployee({
                ...newEmployee,
                type: newType,
                email: (newType.id === EmployeeTypes.BranchWorker.id || newType.id === EmployeeTypes.CompanyWorker.id) ? "" : newEmployee.email
            })
        }
    }

    const handleParamChange = (param: "phone" | "fio" | "email", newValue: string) => {
        setNewEmployee({...newEmployee, [param]: newValue})
    }

    const onClickCreate = () => {
        createEmployee(newEmployee, REQUEST_ID)
    }

    const isValid = () => {
        return PhoneNumber.validateWithMask(newEmployee.phone) && newEmployee.fio
    }

    const getEmployeeTypes = (employeeType: EmployeeType): EmployeeType[] => {
        let employeeTypes: EmployeeType[] = []
        if (EmployeeTypes.getCompanyEmployees().map(type => type.id).includes(employeeType.id)) {
            employeeTypes = EmployeeTypes.getCompanyEmployees()
        } else if (EmployeeTypes.getBranchEmployees().map(type => type.id).includes(employeeType.id)) {
            employeeTypes = EmployeeTypes.getBranchEmployees()
        }
        return employeeTypes
    }

    useEffect(() => {
        if (creatingStatus === "success") {
            creatingRemove()
            navigate(-1)
            enqueueSnackbar("Сотрудник успешно создан", {variant: "success"})
        } else if (creatingStatus === "error") {
            enqueueSnackbar("Ошибка при создании сотрудника", {variant: "error"})
        }
    }, [creatingStatus])

    if (!userInfo) {
        return (
            <></>
        )
    }

    return (
        <OfficePageSketch
            title="Создание нового сотрудника"
            goBack={() => navigate(-1)}
            paperContainer={false}
        >
            <Paper
                component={Grid}
                container
            >
                <Grid item>
                    <Grid
                        container
                        direction={"column"}
                        wrap={"nowrap"}
                        sx={{p: '16px 24px'}}
                        spacing={2}
                    >
                        <Grid item>
                            <FormControl>
                                <InputLabel>{"Тип сотрудника"}</InputLabel>
                                <Select
                                    label={"Тип сотрудника"}
                                    value={newEmployee.type.id}
                                    onChange={event => handleChangeType(event.target.value)}
                                >
                                    {getEmployeeTypes(newEmployee.type)
                                        .filter(type => type.id !== EmployeeTypes.CompanyDirector.id && type.id !== EmployeeTypes.BranchDirector.id)
                                        .map((type, ind) => {
                                            return (
                                                <MenuItem
                                                    value={type.id}
                                                    key={`new-employee-type-${ind}`}
                                                >
                                                    {type.label}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        {newEmployee.type.id !== EmployeeTypes.CompanyWorker.id && newEmployee.type.id !== EmployeeTypes.BranchWorker.id && (
                            <Grid item>
                                <TextField
                                    name={"new-employee-email"}
                                    label={"email"}
                                    value={newEmployee.email}
                                    onChange={e => handleParamChange("email", e.target.value)}
                                />
                            </Grid>
                        )}
                        <Grid item>
                            <TextField
                                name={"new-employee-name"}
                                label={"Имя"}
                                value={newEmployee.fio}
                                onChange={e => handleParamChange("fio", e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <PhoneInput
                                label={"Номер телефона"}
                                value={newEmployee.phone}
                                onChange={e => handleParamChange("phone", e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <StyledButton
                                onClick={onClickCreate}
                                disabled={!isValid()}
                            >
                                Создать
                            </StyledButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </OfficePageSketch>
    )
}

export default CreateEmployeePage