import {Button, Grid, TextField} from "@mui/material";
import {FC, ReactElement, useEffect, useState} from "react";
import TaxiParks from "@kashalot-web/core/dist/TaxiInfo/TaxiParks"
import PaperContainer from "../../lab/Components/PaperContainer";
import StyledButton from "../StyledButton";
import RequisitesForm, {validateRequisitesForm} from "../RequisitesForm";

const YandexIcon = (props: any) => {
    return (
        <Grid item {...props}>
            <img
                width={50}
                src={TaxiParks.YANDEX_TAXI.logo}
            />
        </Grid>
    )
}

interface IYandexFormComponent {
    defaultYandexForm: YandexForm
    saveForm: (newForm: YandexForm) => void
    deleteForm: () => void
}

const YandexFormComponent: FC<IYandexFormComponent> = (props): ReactElement => {
    const {defaultYandexForm, saveForm, deleteForm} = props
    const [yandexForm, setYandexForm] = useState<YandexForm>(defaultYandexForm)
    const yandexHandleTokenChanged = (param: "token" | "clientID" | "name", value: string) => setYandexForm({
        ...yandexForm,
        [param]: value
    })

    useEffect(() => {
        setYandexForm(defaultYandexForm)
    }, [defaultYandexForm])

    const validate = () => {
        if (defaultYandexForm.legalEntity && yandexForm.legalEntity) {
            return yandexForm.token !== defaultYandexForm.token
                || yandexForm.clientID !== defaultYandexForm.clientID
                || yandexForm.name !== defaultYandexForm.name ||
                validateRequisitesForm(defaultYandexForm.legalEntity, yandexForm.legalEntity)
        }
        return yandexForm.token !== defaultYandexForm.token
            || yandexForm.clientID !== defaultYandexForm.clientID
            || yandexForm.name !== defaultYandexForm.name ||
            !!yandexForm.legalEntity
    }

    return (
        <PaperContainer
            title={"Яндекс " + `(${defaultYandexForm.name})`}
            Icon={YandexIcon}
            direction={"column"}
            wrap={"nowrap"}
            container
            spacing={2}
            sx={{p: '16px 0'}}
        >
            <Grid item><TextField
                label={"Название"}
                value={yandexForm.name}
                onChange={e => yandexHandleTokenChanged("name", e.target.value)}
            /></Grid>
            <Grid item><TextField
                label={"Token"}
                value={yandexForm.token}
                onChange={e => yandexHandleTokenChanged("token", e.target.value)}
                sx={{width: 468}}
            /></Grid>
            <Grid item><TextField
                label={"ClientID"}
                value={yandexForm.clientID}
                onChange={e => yandexHandleTokenChanged("clientID", e.target.value)}
                sx={{width: 468}}
            /></Grid>

            <Grid item>
                <RequisitesForm
                    defaultRequisites={yandexForm.legalEntity}
                    onChangeRequisites={newRequisites => {
                        setYandexForm({...yandexForm, legalEntity: {...newRequisites, id: yandexForm.legalEntity?.id || ""}})
                    }}
                />
            </Grid>

            <Grid item container spacing={2}>
                <Grid item><StyledButton
                    disabled={!validate()}
                    onClick={() => saveForm(yandexForm)}
                >
                    Сохранить
                </StyledButton></Grid>
                <Grid item><StyledButton
                    onClick={deleteForm}
                >
                    Удалить
                </StyledButton></Grid>
            </Grid>
        </PaperContainer>
    )
}

export default YandexFormComponent