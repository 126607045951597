import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import OfficePageSketch from "../../../lab/OfficePageSketch";
import {useNavigate} from "react-router-dom";
import {Button, Grid, Paper, TextField, Typography} from "@mui/material";
import InputMask from "react-input-mask";
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import {useAdmin, useAdminRequest} from "@kashalot-web/react/dist";

const CREATE_REQ_ID = "create-dispatcher-office-" + makeID(8)

const CreateDispatcherOfficePage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {createDispatcherOffice} = useAdmin()
    const [status, removeRequest] = useAdminRequest(CREATE_REQ_ID)
    const [dispatcherOfficeForm, setDispatcherOfficeForm] = useState<DispatcherOfficeForm>({
        name: "",
        fio: "",
        email: "",
        phone: "",
    })
    const handleForm = (param: "name" | "fio" | "email" | "phone", value: string) =>
        setDispatcherOfficeForm({...dispatcherOfficeForm, [param]: value})
    const validate = () => {
        return dispatcherOfficeForm.name && dispatcherOfficeForm.fio && dispatcherOfficeForm.email &&
            PhoneNumber.validateWithMask(dispatcherOfficeForm.phone)
    }
    const create = () => {
        createDispatcherOffice(dispatcherOfficeForm, CREATE_REQ_ID)
    }
    useEffect(() => {
        if (status === "success") {
            removeRequest()
            navigate(-1)
        }
    }, [status])

    return (
        <OfficePageSketch
            title={"Создание диспетчерскиой"}
            goBack={() => navigate(-1)}
            paperContainer={false}
        >
            <Paper
                component={Grid}
                container item
                direction={"column"} wrap={"nowrap"}
                spacing={2}
                sx={{pb: '18px'}}
            >
                <Grid item><TextField
                    value={dispatcherOfficeForm.name}
                    label={"Название диспетчерской"}
                    onChange={event => handleForm("name", event.target.value)}
                /></Grid>
                <Grid item><TextField
                    value={dispatcherOfficeForm.fio}
                    label={"ФИО диспетчера"}
                    onChange={event => handleForm("fio", event.target.value)}
                /></Grid>
                <Grid item><TextField
                    value={dispatcherOfficeForm.email}
                    label={"Email диспетчера"}
                    onChange={event => handleForm("email", event.target.value)}
                /></Grid>
                <Grid item><InputMask
                    mask="+7 (999) 999-9999"
                    value={dispatcherOfficeForm.phone ? dispatcherOfficeForm.phone : "+"}
                    onChange={(event) => handleForm("phone", event.target.value)}
                    children={((inputProps: any) =>
                        <TextField
                            {...inputProps}
                            label={"Номер телефона"}
                            type="tel"
                        />) as unknown as ReactNode
                    }
                /></Grid>
                {status === "error" && <Grid item><Typography color={"error"}>Ошибка при создании</Typography></Grid>}
                <Grid item><Button
                    variant={"contained"}
                    disabled={!validate()}
                    onClick={create}
                >Создать</Button></Grid>
            </Paper>
        </OfficePageSketch>
    )
}

export default CreateDispatcherOfficePage