import {FC, ReactElement, useEffect} from "react";
import {useAdmin} from "@kashalot-web/react/dist";
import {Button, Grid, Paper, Typography} from "@mui/material";
import MaterialTable, {MTableToolbar} from "@material-table/core";
import {useNavigate} from "react-router-dom";

const dispatcherOrganizationsToTable = (dispatcherOffices: Array<DispatcherOffice>) => {
    return dispatcherOffices.map(dispatcherOffice => ({
        name: dispatcherOffice.name,
        phone: dispatcherOffice.phoneNumber
    }))
}

const DISPATCHER_OFFICES_REQ_ID = "get-disp-offices-req-asd;f32,a'"

const DispatcherOfficesSection: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {dispatcherOffices, getDispatcherOffices} = useAdmin()

    useEffect(() => {
        getDispatcherOffices(DISPATCHER_OFFICES_REQ_ID)
    }, [])

    return (
        <MaterialTable
            style={{width: "100%"}}
            title={<Typography variant={"h4"}>Диспетчерские</Typography>}
            columns={[
                {title: "Название", field: "name"},
                {title: "Номер телефона", field: "phone"},
            ]}
            data={dispatcherOrganizationsToTable(dispatcherOffices || [])}
            options={{
                pageSize: 10,
                pageSizeOptions: [5, 10, 20]
            }}
            components={{
                Toolbar: props => {
                    return (
                        <Grid container direction="column" wrap="nowrap">
                            <MTableToolbar {...props} />
                            <Grid item container sx={{p: '9px'}}>
                                <Paper
                                    component={Grid}
                                    item container
                                    sx={{p: '9px'}}
                                >
                                    <Button variant="contained" onClick={() => navigate('/create_dispatcher_office')}>
                                        Создать диспетчерскую
                                    </Button>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
            }}
        />
    )
}

export default DispatcherOfficesSection