import {FC, ReactElement, useEffect, useState} from "react";
import {Grid, TextField} from "@mui/material";
import TaxiParks from "@kashalot-web/core/dist/TaxiInfo/TaxiParks"
import TaxiClasses from "@kashalot-web/core/dist/TaxiInfo/TaxiClasses"
import StyledButton from "../StyledButton";
import BaseVendorForm from "./BaseVendorForm";
import RequisitesForm, {validateRequisitesForm} from "../RequisitesForm";

const emptyForm: Zero68Form = {
    id: '',
    name: '',
    active: true,
    vendor: TaxiParks.Zero68,
    carClasses: TaxiClasses.getAllClasses(),
    vendorName: '068',
    login: '',
    pass: '',
    url: '',
    legalEntityID: "",
    legalEntity: null
}

export interface IZero68FormComponent {
    defaultForm?: Zero68Form
    value?: Zero68Form
    onSave?: (newZero68Form: Zero68Form) => void
    onDelete?: () => void
    onCreate?: (newZero68Form: Zero68Form) => void
}

const Zero68FormComponent: FC<IZero68FormComponent> = (props): ReactElement => {
    const {defaultForm, value, onSave, onDelete, onCreate} = props

    const [form, setForm] = useState<Zero68Form>(value || defaultForm || emptyForm)

    const validate = () => {
        if (defaultForm?.legalEntity && form.legalEntity) {
            return defaultForm?.login !== form.login
                || defaultForm.pass !== form.pass
                || defaultForm.name !== form.name ||
                validateRequisitesForm(defaultForm.legalEntity, form.legalEntity)
        }
        return defaultForm?.login !== form.login
            || defaultForm.pass !== form.pass
            || defaultForm.name !== form.name ||
            !!form.legalEntity
    }

    useEffect(() => {
        if (value) {
            setForm(value)
        }
    }, [value])

    return (
        <Grid
            container item
            direction={"column"} wrap={"nowrap"}
            spacing={2}
        >
            <BaseVendorForm
                vendorForm={form}
                onChange={newForm => setForm({...form, ...newForm})}
            />

            <Grid item>
                <TextField
                    label={"Логин"}
                    value={form.login}
                    onChange={e => setForm({...form, login: e.target.value})}
                />
            </Grid>
            <Grid item>
                <TextField
                    label={"Пароль"}
                    value={form.pass}
                    onChange={e => setForm({...form, pass: e.target.value})}
                />
            </Grid>

            <Grid item>
                <RequisitesForm
                    defaultRequisites={form.legalEntity}
                    onChangeRequisites={newRequisites => setForm({...form, legalEntity: {...newRequisites, id: form.legalEntity?.id || ""}})}
                />
            </Grid>

            {(onSave || onCreate || onDelete) && (
                <Grid item container spacing={2}>
                    {onCreate && (
                        <Grid item>
                            <StyledButton
                                onClick={() => onCreate(form)}
                            >
                                Создать
                            </StyledButton>
                        </Grid>
                    )}
                    {onSave && (
                        <Grid item>
                            <StyledButton
                                onClick={() => onSave(form)}
                                disabled={!validate()}
                            >
                                Сохранить
                            </StyledButton>
                        </Grid>
                    )}
                    {onDelete && (
                        <Grid item>
                            <StyledButton
                                onClick={() => onDelete()}
                            >
                                Удалить
                            </StyledButton>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    )
}


export default Zero68FormComponent