import {FC, ReactElement, useEffect} from "react"
import {useCreatedOrders, useDispatcherRequest, useOrdersRequest} from "@kashalot-web/react/dist"
import useDispatcher from "@kashalot-web/react/dist/useDispatcher"
import OrdersTable from "../../../LocalUICore/OrdersTable"
import {GET_CLIENTS_ID, GET_ORDER_WITH_FILTERS_ID} from "../DispatcherRequestIds"


const GET_REPORT_ID = "get-report-m24-29mv"


const FinancesSection: FC = (): ReactElement => {
    const {getOrdersByFilters, ordersWithFilters, applyFilters, filters, getReport} = useCreatedOrders()
    const [getOrdersReqStatus] = useOrdersRequest(GET_ORDER_WITH_FILTERS_ID)

    const {getClients, clientsManager} = useDispatcher()
    const [getClientsReqStatus] = useDispatcherRequest(GET_CLIENTS_ID)


    useEffect(() => {
        getClients(GET_CLIENTS_ID)
        getOrdersByFilters(GET_ORDER_WITH_FILTERS_ID)
    }, [])


    return (
        <OrdersTable
            loading={getOrdersReqStatus === "pending" || getClientsReqStatus === "pending"}
            orders={ordersWithFilters}
            filters={filters}
            applyFilters={newFilters => applyFilters(newFilters, GET_ORDER_WITH_FILTERS_ID)}
            getReport={() => getReport(GET_REPORT_ID)}
            clients={clientsManager ? clientsManager.getOrganizations() : []}
        />
    )
}

export default FinancesSection