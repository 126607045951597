import {FC, ReactElement} from "react";
import MainPageSketch from "../../../lab/MainPageSketch";
import {useProfile} from "@kashalot-web/react/dist";
import {useNavigate} from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DispatcherOfficesSection from "./DispatcherOfficesSection";
import BadgeIcon from '@mui/icons-material/Badge';
import ClientsSection from "./ClientsSection";
import PersonIcon from '@mui/icons-material/Person';

interface IAdminMainPage {
    section: "dispatcher_offices" | "clients"
}

const AdminMainPage: FC<IAdminMainPage> = ({section}): ReactElement => {
    const {logout} = useProfile()
    const navigate = useNavigate()

    return (
        <MainPageSketch
            title="Админ панель"
            sections={[
                {
                    title: "Диспетчерские",
                    name: "dispatcher_offices",
                    icon: <BadgeIcon/>,
                    onClick: () => navigate('/dispatcher_offices'),
                    body: <DispatcherOfficesSection/>,
                },
                {
                    title: "Клиенты",
                    name: "clients",
                    icon: <PersonIcon/>,
                    onClick: () => navigate('/clients'),
                    body: <ClientsSection/>,
                },
                {
                    title: "Выход",
                    name: "exit",
                    icon: <ExitToAppIcon/>,
                    onClick: () => logout(),
                    body: <></>,
                }
            ]}
            selectedSection={section}
        />
    )
}

export default AdminMainPage