import {FC, ReactElement} from "react"
import {useOrganization} from "@kashalot-web/react/dist"
import OrganizationProfile from "../../../LocalUICore/OrganizationProfile";
import {CircularProgress, Grid, Paper, Typography} from "@mui/material";

const MainSection: FC = (): ReactElement => {
    const {organization} = useOrganization()

    if (!organization) {
        return (
            <Grid container alignItems={"center"} direction={"column"} wrap={"nowrap"}>
                <CircularProgress />
                <Typography sx={{al: '16px'}}>Загрузка...</Typography>
            </Grid>
        )
    }

    return (
        <>
            <OrganizationProfile
                profile={organization}
            />
        </>
    )
}

export default MainSection