import {FC, ReactElement, useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import OfficePageSketch from "../../../lab/OfficePageSketch";
import {useNavigate} from "react-router-dom";
import {useDispatcher, useDispatcherRequest} from "@kashalot-web/react/dist";

const CREATE_DISPATCHER_GROUP_ID = "create-dispatcher-group-id"

const CreateGroupPage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {createGroup} = useDispatcher()
    const [statusCreating] = useDispatcherRequest(CREATE_DISPATCHER_GROUP_ID)

    const [groupForm, setGroupForm] = useState<DispatcherGroupForm>({name: ""})
    const handleGroupForm = (param: "name", newValue: string) => setGroupForm({...groupForm, [param]: newValue})
    const validate = () => !!groupForm.name
    const create = () => {
        if (validate()) {
            createGroup(groupForm, CREATE_DISPATCHER_GROUP_ID)
        }
    }

    useEffect(() => {
        if (statusCreating === "success") {
            navigate(-1)
        }
    }, [statusCreating])

    return (
        <OfficePageSketch
            title={"Создание группы"}
            goBack={() => navigate(-1)}
            paperContainer={true}
        >
            <Grid item container direction={"column"} wrap={"nowrap"} spacing={2} sx={{p: '0 14px'}}>
                <Grid item><Typography variant="h5">Информация о группе</Typography></Grid>
                <Grid item><TextField
                    value={groupForm.name}
                    onChange={event => handleGroupForm("name", event.target.value)}
                    label={"Навазние группы"}
                /></Grid>
                <Grid item><Button
                    onClick={create}
                    variant={"contained"}
                    disabled={!validate()}
                >Создать</Button></Grid>
            </Grid>
        </OfficePageSketch>
    )
}

export default CreateGroupPage