import {FC, ReactElement, useEffect} from "react";
import PaperContainer from "../../../lab/Components/PaperContainer";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import {useOrganization, useOrganizationRequest} from "@kashalot-web/react/dist";
import RequisitesForm from "../../../LocalUICore/RequisitesForm";

const SAVE_COMPANY_REQ = "save-company-req-klasdlfnll"

interface ICompanyRequisitesContainer {
    company: Company | Branch
    reset: () => void
}

const CompanyRequisitesContainer: FC<ICompanyRequisitesContainer> = ({company, reset}): ReactElement => {
    const {updateOrganizationDetails} = useOrganization()
    const [status, remove] = useOrganizationRequest(SAVE_COMPANY_REQ)

    useEffect(() => {
        if (status === "success") {
            remove()
            reset()
        }
    }, [status])

    return (
        <PaperContainer
            title="Реквизиты"
            Icon={AccountBalanceIcon}
            sx={{p: '16px 0'}}
        >
            {company.legalEntities.map((legalEntity, index) => {
                return (
                    <RequisitesForm
                        key={`requisites-${index}-${legalEntity.id}`}
                        defaultRequisites={legalEntity}
                    />
                )
            })}
            {/*<RequisitesForm*/}
            {/*    defaultRequisites={company}*/}
            {/*    onSaveRequisites={newRequisites => updateOrganizationDetails({...company, ...newRequisites}, SAVE_COMPANY_REQ)}*/}
            {/*/>*/}
        </PaperContainer>
    )
}

export default CompanyRequisitesContainer