import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import {Autocomplete, Button, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import InputMask from "react-input-mask";
import {useDispatcher} from "@kashalot-web/react";
import {useDispatcherRequest} from "@kashalot-web/react/dist";
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import {useNavigate} from "react-router-dom";

const CREATE_COMPANY_REQ_ID = "create-company-req-" + makeID(8)

const CreateCompany: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {createCompany} = useDispatcher()
    const [statusCreate, removeCreateRequest] = useDispatcherRequest(CREATE_COMPANY_REQ_ID)
    const [companyForm, setCompanyForm] = useState<CompanyForm>({
        name: "",
        fio: "",
        email: "",
        phone: "",
    })
    const handleCompanyForm = (param: "name" | "fio" | "email" | "phone", value: string) =>
        setCompanyForm({...companyForm, [param]: value})
    const validate = () => {
        const {name, fio, email, phone} = companyForm
        return name && fio && email && PhoneNumber.validateWithMask(phone) && statusCreate !== "pending"
    }
    const onCreate = () => {
        createCompany(companyForm, CREATE_COMPANY_REQ_ID)
    }
    useEffect(() => {
        if (statusCreate === "success") {
            removeCreateRequest()
            navigate(-1)
        }
    }, [statusCreate])


    return (
        <Grid
            item container
            direction={"column"}
            wrap={"nowrap"}
            spacing={2}
        >
            <Grid item><Typography variant="h5">Контактные данные</Typography></Grid>
            <Grid item><TextField
                label={"Название компании"}
                value={companyForm.name}
                onChange={event => handleCompanyForm("name", event.target.value)}
            /></Grid>
            <Grid item><TextField
                label={"ФИО управляющего"}
                value={companyForm.fio}
                onChange={event => handleCompanyForm("fio", event.target.value)}
            /></Grid>
            <Grid item><TextField
                label={"Email управляющего"}
                value={companyForm.email}
                onChange={event => handleCompanyForm("email", event.target.value)}
            /></Grid>
            <Grid item>
                <InputMask
                    mask="+7 (999) 999-9999"
                    value={companyForm.phone ? companyForm.phone : "+"}
                    onChange={(event) => handleCompanyForm("phone", event.target.value)}
                    children={((inputProps: any) =>
                        <TextField
                            {...inputProps}
                            label={"Номер телефона"}
                            type="tel"
                        />) as unknown as ReactNode
                    }
                />
            </Grid>
            {statusCreate === "error" && <Grid item><Typography color={"error"}>Ошибка при создании</Typography></Grid>}
            <Grid item><Button
                variant={"contained"}
                onClick={onCreate}
                disabled={!validate()}
            >Создать</Button></Grid>
        </Grid>
    )
}

export default CreateCompany