import {Autocomplete, CircularProgress, TextField} from "@mui/material"
import {FC, ReactElement, useEffect, useState} from "react"

export interface IAddressInput {
    id: string
    label: string
    defaultValue: string
    onChange: (newAddress: string) => void
    suggestionsRequests: SuggestionsRequest[]
    pushSuggestionRequest: (id: string, inputValue: string) => void
}

const AddressInput: FC<IAddressInput> = (props): ReactElement => {
    const {id, defaultValue, onChange, suggestionsRequests, pushSuggestionRequest, label} = props

    const [inputValue, setInputValue] = useState<string>(defaultValue)
    const [loading, setLoading] = useState<boolean>(false)
    const [options, setOptions] = useState<{ title: string, value: string }[]>([])
    const [timer, setTimer] = useState<any>(0)

    useEffect(() => {
        setInputValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        clearTimeout(timer)
        if (defaultValue !== inputValue) {
            setLoading(true)
            setOptions([])
            setTimer(setTimeout(() => pushSuggestionRequest(id, inputValue), 500))
        } else {
            setLoading(false)
        }
    }, [inputValue])

    useEffect(() => {
        const targetSuggestionsRequest = suggestionsRequests.find(request => request.id === id)
        if (targetSuggestionsRequest) {
            if (!targetSuggestionsRequest.pending) {
                setOptions(targetSuggestionsRequest.suggestionsList)
                setLoading(false)
            }
        }
    }, [suggestionsRequests])

    return (
        <Autocomplete
            sx={{width: 300}}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            options={options}
            loading={loading}
            filterOptions={(options, state) => options}
            value={{title: inputValue, value: ""}}
            onChange={(_, value) => onChange(value?.title || "")}
            inputValue={inputValue}
            onInputChange={(_, value) => setInputValue(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}

export default AddressInput