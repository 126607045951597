import {FC, ReactElement, useEffect, useState} from "react";
import PaperContainer from "../lab/Components/PaperContainer";
import PersonIcon from "@mui/icons-material/Person";
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import PhoneInput from "./PhoneInput";
import StyledButton from "./StyledButton";
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"

interface IEmployeeProfile {
    defaultEmployee: Employee
    save: (newEmployee: Employee) => void
    reactivate: (newStatus: boolean) => void
}

const EmployeeProfile: FC<IEmployeeProfile> = (props): ReactElement => {
    const {defaultEmployee, save, reactivate} = props

    const [employee, setEmployee] = useState<Employee>(defaultEmployee)

    useEffect(() => {
        setEmployee(defaultEmployee)
    }, [defaultEmployee])

    const handleChangeType = (newTypeID: any) => {
        const newType = EmployeeTypes.getAllTypes().find(type => type.id === newTypeID)
        if (newType) {
            setEmployee({...employee, type: newType})
        }
    }

    const getEmployeeTypes = (employeeType: EmployeeType): EmployeeType[] => {
        let employeeTypes: EmployeeType[] = []
        if (EmployeeTypes.getCompanyEmployees().map(type => type.id).includes(employeeType.id)) {
            employeeTypes = EmployeeTypes.getCompanyEmployees()
        } else if (EmployeeTypes.getBranchEmployees().map(type => type.id).includes(employeeType.id)) {
            employeeTypes = EmployeeTypes.getBranchEmployees()
        }
        return employeeTypes
    }

    const isValidForUpdating = () => {
        return employee.fio !== defaultEmployee.fio ||
            (employee.phone !== defaultEmployee.phone && PhoneNumber.validateWithMask(employee.phone)) ||
            employee.email !== defaultEmployee.email ||
            employee.type.id !== defaultEmployee.type.id
    }

    return (
        <PaperContainer
            title="Профиль"
            Icon={PersonIcon}
            sx={{p: '16px 0'}}
        >
            <Grid item>
                <Grid
                    container
                    direction={"column"}
                    wrap={"nowrap"}
                    spacing={2}
                >
                    <Grid item>
                        <FormControl>
                            <InputLabel>{"Тип сотрудника"}</InputLabel>
                            <Select
                                label={"Тип сотрудника"}
                                value={employee.type.id}
                                onChange={event => handleChangeType(event.target.value)}
                            >
                                {getEmployeeTypes(employee.type)
                                    .map((type, ind) => {
                                        return (
                                            <MenuItem
                                                value={type.id}
                                                key={`new-employee-type-${ind}`}
                                            >
                                                {type.label}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {employee.type.id !== EmployeeTypes.CompanyWorker.id && employee.type.id !== EmployeeTypes.BranchWorker.id && (
                        <Grid item>
                            <TextField
                                label={"email"}
                                value={employee.email}
                                onChange={e => setEmployee({...employee, email: e.target.value})}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <TextField
                            label={"Имя"}
                            value={employee.fio}
                            onChange={e => setEmployee({...employee, fio: e.target.value})}
                        />
                    </Grid>
                    <Grid item>
                        <PhoneInput
                            label={"Номер телефона"}
                            value={employee.phone}
                            onChange={e => setEmployee({...employee, phone: e.target.value})}
                        />
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item>
                            <StyledButton disabled={!isValidForUpdating()} onClick={() => save(employee)}>
                                Сохранить
                            </StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton onClick={() => reactivate(!employee.active)}>
                                {employee.active ? 'Отключить' : 'Включить'}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PaperContainer>
    )
}

export default EmployeeProfile